import { useContext, useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginFormSchema from '../schema/loginForm.schema';
import AuthContext from '../../contexts/auth-context';
import AppContext from '../../contexts/app-context';
import useAxios from '../../hooks/useAxios';
import AuthFormStyle from './AuthForm.module';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import { validateAuthFormToken } from '../../Utils/validateHttpResponse';
import Toastify from '../ui/Toastify';
import { toastify_status } from '../config/variables';

const AuthForm = () => {
  const classes = AuthFormStyle();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext);
  const direction = appCtx.direction;
  const { operation } = useAxios();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const [error, setError] = useState(undefined);
  const [validationError, setValidationError] = useState(undefined);
  const [message, setMessage] = useState('');
  const [visibility, setVisibility] = useState(false)
  const [loadingAuth, setLoadingAuth] = useState(false)
  const { t } = useTranslation('auth')

  const submitHandler = async (event) => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;
    const loginForm = { email: enteredEmail, password: enteredPassword };

    try {
      const { error } = LoginFormSchema.validate(loginForm, {
        abortEarly: false,
      });
      if (!error) {
        setLoadingAuth(true)
        setValidationError(undefined);
        operation(
          {
            method: 'POST',
            url: '/api/user/token/',
            headers: {},
            data: {
              email: enteredEmail,
              password: enteredPassword,
            },
          },
          handleSetResponse,
          setError
        );
      } else {
        const newErrors = {};
        error.details.forEach((detail) => {
          const path = detail.path[0];
          const message = detail.message;
          newErrors[path] = message;
        });
        setValidationError(newErrors);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetResponse = (response) => {
    setLoadingAuth(false)
    if (response && validateAuthFormToken(response)) {
      authCtx.login(response.token);
      return navigate('/', { replace: true });
    } else {
      Toastify({message: 'invalid Token', state: toastify_status.ERROR})
    }
  }

  useEffect(() => {
    const lastVisit = localStorage.getItem('lastVisit');
    const currentTime = new Date().getTime();
    const oneDay = 24 * 60 * 60 * 1000;

    if (lastVisit && (currentTime - lastVisit < oneDay)) {
      setMessage('greetingCurrentUser');
    } else {
      setMessage('greetingNewUser');
    }
    localStorage.setItem('lastVisit', currentTime.toString());
  }, [direction]);

  useEffect(() => {
    if(error) {
      setLoadingAuth(false)
    }
  },[error])

  return (
    <Box sx={classes.auth_container} dir={direction}>
      <Typography component="h1" sx={classes.auth_form_h1}>
        {t(message)}
      </Typography>
      <Typography component="h2" sx={classes.auth_form_h2}>
        {t('sign_in')}
      </Typography>
      <Box component="form" sx={classes.form_container} onSubmit={submitHandler}>
        {error && (
          <Alert severity="error">
            {error.message}
            <br />
            {error.response?.data['non_field_errors']}
          </Alert>
        )}
        <div>
          <TextField
            label={t('email')}
            InputLabelProps={{
              sx: classes.label_style
            }}
            variant="standard"
            type="email"
            sx={[classes.input_style,
            {
              '& label': {
                transformOrigin: direction === 'rtl' ? 'right !important' : 'left !important',
                left: 'inherit !important',
                right: 'inherit !important',
              },
              '&  .MuiFormHelperText-root.Mui-error': {
                textAlign: direction === 'rtl' ? 'right' : 'left',
              },
            }
            ]}
            name="email"
            inputRef={emailInputRef}
            error={validationError?.email ? true : false}
            helperText={validationError?.email}
          />
          <TextField
            label={t('password')}
            InputLabelProps={{
              sx: classes.label_style
            }}
            variant="standard"
            type={visibility ? 'text' : 'password'}
            sx={[classes.input_style,
            {
              '& label': {
                transformOrigin: direction === 'rtl' ? 'right !important' : 'left !important',
                left: 'inherit !important',
                right: 'inherit !important',
              },
              '&  .MuiFormHelperText-root.Mui-error': {
                textAlign: direction === 'rtl' ? 'right' : 'left',
              },
            }
            ]}
            name="password"
            inputRef={passwordInputRef}
            error={validationError?.password ? true : false}
            helperText={validationError?.password}
            FormHelperTextProps={classes.helper_text_style}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setVisibility(prev => !prev)}>
                    {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Button variant="contained" sx={classes.submit_button} type="submit">
          {t('login')}
          {loadingAuth && <CircularProgress sx={{color: 'white', margin: '0 10px'}} size={20}/>}
        </Button>
      </Box>
      <Typography sx={classes.contact_us}>
        {t('dont_have_account')}
        <Typography component="span" sx={classes.contact_us_nav}>
          {t('contact_us')}
        </Typography>
      </Typography>
    </Box>
  );
};

export default AuthForm;
