import React, {  useState, createContext, useContext, useEffect } from 'react';
import AuthContext from './auth-context';

const AppContext = createContext({
    selectedUser:'',
    usersList:'',
    userRole: null,
    language: '',
    direction: '',
    openSnackBar: '',
    snackBarTitle: '',
    searchByDateQuery: '',
    searchByFrequencyDateQuery: '',
    insuranceChartSearchBy: '',
    reFetchChartsData: false,
    reFetchUsersList: false,
    addSelectedUser: (selectedUser) => { },
    removeSelectedUser: () => { },
    addUsersList: (usersList) => { },
    changeLanguage: (newLanguage) => { },
    handleOpenSnackBar: () => {},
    handleSetSearchByDateQuery: () => {},
    handleSetSearchByFrequencyDateQuery: () => {},
    handleSetInsuranceChartSearchBy: () => {},
    handleReFetchChartsData: () => {},
    handleReFetchUsersList: () => {},
});

export const AppContextProvider = (props) => {
    let initialSelectedUser = localStorage.getItem('sehteq-selecteduser');
    let retrievedUsersList = localStorage.getItem('sehteq-userslist');
    let initialLanguage = localStorage.getItem('language') || 'en';
    let initialDirection = localStorage.getItem('direction') || 'ltr';

    const initialUsersList = JSON.parse(retrievedUsersList);
    const [selectedUser, setSelectedUser] = useState(initialSelectedUser);
    const [usersList, setUsersList] = useState(initialUsersList);
    const [userRole, setUserRole] = useState(undefined);
    const [language, setLanguage] = useState(initialLanguage);
    const [direction, setDirection] = useState(initialDirection);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarTitle, setSnackBarTitle] = useState('');
    const [searchByDateQuery, setSearchByDateQuery] = useState('');
    const [searchByFrequencyDateQuery, setSearchByFrequencyDateQuery] = useState('daily');
    const [insuranceChartSearchBy, setInsuranceChartSearchBy] = useState('date');
    const [reFetchChartsData, setReFetchChartsData] = useState(false);
    const [reFetchUsersList, setReFetchUsersList] = useState(false);

    const authCtx = useContext(AuthContext);
    const userProfile = authCtx.userProfile;

    const getUserRole = (userProfile) => {
        try {
          const roles = userProfile.user['roles'];
          const doctor = roles.find((element) => element === "doctor");
          const insurance_admin = roles.find((element) => element === "insurance_admin");
          const facility_admin = roles.find((element) => element === "facility_admin");
          const pharmacy_admin = roles.find((element) => element === "pharmacy_admin");
          const patient = roles.find((element) => element === "patient");

          if (typeof doctor !== "undefined") {
            setUserRole("doctor");
          } else if (typeof insurance_admin !== "undefined") {
            setUserRole("insurance_admin");
          } else if (typeof facility_admin !== "undefined") {
            setUserRole("facility_admin");
          } else if (typeof pharmacy_admin !== "undefined") {
            setUserRole("pharmacy_admin");
          } else if (typeof patient !== "undefined") {
            setUserRole("patient");
            addSelectedUser({'userProfile':userProfile}) //<-- If the patient loggedin, then user is part of profile object
          }
        } catch (e){
          //console.log("wait: loading user role");
        }
      };
  
    useEffect(() => {
        
        if (typeof userProfile !== "undefined") {
            getUserRole(userProfile)
        }
        // eslint-disable-next-line
    },[JSON.stringify (userProfile)]);

    const removeSelectedUser =() => {
        setSelectedUser('')
        localStorage.removeItem('sehteq-selecteduser');
    }

    const addSelectedUser = (selectedUser) => {
        setSelectedUser(selectedUser);
        localStorage.setItem('sehteq-selecteduser', selectedUser);
    };

    const addUsersList = (usersList) => {
        setUsersList(usersList);
        const stringifyUsersList = JSON.stringify(usersList);
        localStorage.setItem('sehteq-userslist', stringifyUsersList);
    };

    const changeLanguage = (newLanguage) => {
      let newDirection = newLanguage === 'en' ? 'ltr' : 'rtl'

      setLanguage(newLanguage)
      setDirection(newDirection)
      localStorage.setItem('language', newLanguage)
      localStorage.setItem('direction', newDirection)
    }

    const handleOpenSnackBar = (title) => {
      setSnackBarTitle(title)
      setOpenSnackBar((pre) => !pre)
    }

    const handleSetSearchByDateQuery = (dateQuery) => {
      setSearchByDateQuery(dateQuery)
    }

    const handleSetSearchByFrequencyDateQuery = (frequencyDate) => {
      setSearchByFrequencyDateQuery(frequencyDate)
    }

  const handleSetInsuranceChartSearchBy = (searchBy) => {
    setInsuranceChartSearchBy(searchBy)
  }

  const handleReFetchChartsData = () => {
    setReFetchChartsData(true)
    setTimeout(() => setReFetchChartsData(false), 500)
  }

  const handleReFetchUsersList = () => {
    setReFetchUsersList(true)
    setTimeout(() => setReFetchUsersList(false), 500)
  }

    const contextValue = {
        selectedUser: selectedUser,
        usersList: usersList,
        userRole: userRole,
        language: language,
        direction: direction,
        openSnackBar: openSnackBar,
        snackBarTitle:snackBarTitle,
        searchByDateQuery: searchByDateQuery,
        searchByFrequencyDateQuery: searchByFrequencyDateQuery,
        insuranceChartSearchBy: insuranceChartSearchBy,
        reFetchChartsData: reFetchChartsData,
        reFetchUsersList: reFetchUsersList,
        removeSelectedUser: removeSelectedUser,
        addSelectedUser: addSelectedUser,
        addUsersList: addUsersList,
        changeLanguage: changeLanguage,
        handleOpenSnackBar: handleOpenSnackBar,
        handleSetSearchByDateQuery: handleSetSearchByDateQuery,
        handleSetSearchByFrequencyDateQuery: handleSetSearchByFrequencyDateQuery,
        handleSetInsuranceChartSearchBy: handleSetInsuranceChartSearchBy,
        handleReFetchChartsData: handleReFetchChartsData,
        handleReFetchUsersList: handleReFetchUsersList
    };

    return (
        <AppContext.Provider value={contextValue}>
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContext;