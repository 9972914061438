import { useContext, useState, useEffect } from 'react';
// import { NavLink } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import UserProfilePageStyle from './UserProfilePage.module';
import AuthContext from '../../contexts/auth-context';
import AppContext from '../../contexts/app-context';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { urls, PAGE_SIZE, toastify_status, searchByStateButtons } from '../../components/config/variables';
import EncounterCard from '../../components/users/EncounterCard/EncounterCard';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import AnalysisPage from '../AnalysisPage/AnalysisPage';
import SearchInput from '../../components/ui/SearchInput';
import {useTranslation} from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress';
import { validateEncountersList } from '../../Utils/validateHttpResponse';
import Toastify from '../../components/ui/Toastify';

const PharmacyProfilePage = () => {
  const classes = UserProfilePageStyle();

  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext)
  const direction = appCtx.direction
  const token = authCtx.token;
  const { operation } = useAxios();

  const [doctorEncounters, setDoctorEncounters] = useState([]);
  const [doctorEncountersError, setDoctorEncountersError] = useState(undefined);
  const [doctorEncountersCards, setDoctorEncountersCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [dateValidateError, setDateValidateError] = useState(false);
  const [loadingEcounters, setLoadingEncounters] = useState(true);
  
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const [disaabledSearchButton, setDisabledSearchButton] = useState(true);
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const {t} = useTranslation('doctorProfilePage')
  const [searchByState, setSearchByState] = useState('');

  const SearchByMenuItems = [
    { key: 1, label: t('Search.Date'), value: 'date' },
    { key: 2, label: t('Search.ProviderName'), value: 'provider_name' },
    { key: 3, label: t('Search.PatientName'), value: 'patient_name' },
    { key: 4, label: t('Search.DrugName'), value: 'drug_name' },
    { key: 5, label: t('Search.State'), value: 'state' },
  ];

  const sortByDate = (array) => {
    return array.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  };

  const setFilteredDoctorEncounters = (encounters) => {
    if(validateEncountersList(encounters)) {
      setLoadingEncounters(false)
      const sortedDoctorEncounters = sortByDate(encounters.results);
      setDoctorEncounters(sortedDoctorEncounters);
      setDoctorEncountersCards(reformateData(sortedDoctorEncounters));
      setCount(Math.ceil(encounters.count / PAGE_SIZE));
    } else {
      console.log('invlid encounters list response')
      Toastify({message: 'invlid encounters list response', state: toastify_status.ERROR})
    }
  };

  const get_encounters = (filter) => {
    setLoadingEncounters(true)
    operation(
      {
        method: 'GET',
        url: urls.LIST_ENCOUNTERS + filter,
        headers: { Authorization: `token ${token}` },
        data: {},
      },
      setFilteredDoctorEncounters,
      setDoctorEncountersError
    );
  }

  useEffect(() => {
    let filter;

    if (searchButtonClicked) {
      if (searchBy === 'date' && fromDate && toDate) {
        filter = `?from_date=${fromDate}&to_date=${toDate}&page=${page}&page_size=${PAGE_SIZE}`;
      } else if (searchBy === 'state') {
        filter = `?state=${searchByState}&page=${page}&page_size=${PAGE_SIZE}`
      } else {
        const selectedSearchBy = searchBy === 'provider_name' ? '?provider=' : searchBy === 'patient_name' ? '?patient=' : searchBy === 'state' ? '?state=' : '?drugname=';
        const selectedSearchQuery = searchBy === "provider_name" && searchBy === 'patient_name' ? searchQuery.toLowerCase() : searchQuery;

        filter = searchQuery
          ? `${selectedSearchBy}${selectedSearchQuery}&page=${page}&page_size=${PAGE_SIZE}`
          : `?page=${page}&page_size=${PAGE_SIZE}`;
      }
      get_encounters(filter);
    } else {
      // If search button was not clicked, use default filter for pagination
      get_encounters(`?page=${page}&page_size=${PAGE_SIZE}`);
    }
    // eslint-disable-next-line
  }, [page, searchButtonClicked]);


  const handleChangeSearchBy = (event) => {
    setSearchBy(event.target.value);
    setDateValidateError(false);
    setSearchQuery('');
    setFromDate('')
    setToDate('')
    setSearchByState('')
    setSearchButtonClicked(false)
    setPage(1)
  };

  const reformateData = (encounters) => {
    if (typeof encounters !== 'undefined') {
      return encounters.map((patientEncounter) => {
        return <EncounterCard key={patientEncounter.id} encounter={patientEncounter} />;
      });
    }
  };

  const handleChangeSearch = (e) => {
    const {value, name} = e.target

    if (searchBy === 'date') {
      const currentDate = new Date();
      const date = new Date(value);

      if (date < currentDate) {
        if(name === 'fromDate'){
          setFromDate(value);
        } else if (name === 'toDate'){
          setToDate(value);
        }
        setDateValidateError(false);
      } else {
        setDateValidateError(true);
      }

    } else {
      setSearchQuery(value);
    }
  };

   // set date validate to error if from date bigger than to date
   useEffect(() => {
    if(toDate){
      if(fromDate > toDate){
        setDateValidateError(true);
      } else {
        setDateValidateError(false);
      }
    } 
  }, [fromDate, toDate])

  // set disabled search button to true if there is date validate error of there is no search fromDate or searchQuery
  useEffect(() => {
    const isValidDate = fromDate && toDate && !dateValidateError;
    const isValidSearch = searchQuery && !dateValidateError;
  
    if (isValidDate || isValidSearch) {
      setDisabledSearchButton(false);
    } else {
      setDisabledSearchButton(true);
    }
  }, [fromDate, toDate, searchQuery, dateValidateError]);
  
  const handleSearch = (state) => {
    setPage(1);
    setSearchButtonClicked(true);
    if (searchBy === 'date') {
      appCtx.handleSetSearchByDateQuery(`?from_date=${fromDate}&to_date=${toDate}`)
      get_encounters(`?from_date=${fromDate}&to_date=${toDate ? toDate : fromDate}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'provider_name') {
      get_encounters(`?provider=${searchQuery.toLowerCase()}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'patient_name') {
      get_encounters(`?patient=${searchQuery.toLowerCase()}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'drug_name') {
      get_encounters(`?drugname=${searchQuery}&page=1&page_size=${PAGE_SIZE}`);
    } else if (searchBy === 'state') {
      get_encounters(`?state=${state}&page=1&page_size=${PAGE_SIZE}`);
    } else {
      console.log ("Unknown filter")
    }
  };

  const defaultSearch = () => {
    setSearchButtonClicked(false)
    if(page !== 1) {
      setPage(1);
    } else {
      get_encounters(`?page=${page}&page_size=${PAGE_SIZE}`)
    }
  }

  const handleClearSearch = () => {
      setSearchQuery('');
      setSearchBy('')
      setFromDate('')
      defaultSearch()
  };

  const handleClearDate = (value) => {
    if(value === 'fromDate'){
      setFromDate('')
    } else if (value === 'toDate'){
      setToDate('')
    }
    setDateValidateError(false);
    appCtx.handleSetSearchByDateQuery('')
    defaultSearch()
  }

 // this function called when select search by encounter state and click state button
  const handleSelectEncounterState = (btn) => {
    if(btn.name === searchByState) {
      setSearchByState('')
      defaultSearch()
    } else {
      handleSearch(btn.name)
      setSearchByState(btn.name)
    }
  }

  return authCtx.error ? (
    <Alert severity="error">
      <AlertTitle>Info Not Found</AlertTitle>
      Sorry, no Info was found.
    </Alert>
  ) : (
    <Box>
      <Box sx={classes.container}>
        <AnalysisPage />
        {/* <NavLink to={'/analysis-page'}>
          <Button sx={classes.analysis_page_button} variant="contained">
            Analysis Page
          </Button>
        </NavLink> */}
        {doctorEncountersError ? (
          <Alert severity="error">
            <AlertTitle>Encounters Not Found</AlertTitle>
            Sorry, no Encounters was found.<br />
          </Alert>
        ) : (
          <Box sx={classes.user_encounter_container}>
            <Box dir={direction}>
               <Typography sx={classes.sub_title}>{t('EncountersHistory')}</Typography>
             </Box>
            <Box sx={classes.search_container}>
              <FormControl fullWidth sx={classes.search_by} size="small">
                <InputLabel>{t('Search.SearchBy')}</InputLabel>
                <Select
                  label={t('Search.SearchBy')}
                  value={searchBy}
                  onChange={handleChangeSearchBy}
                  disabled={!doctorEncounters || doctorEncounters.length === 0}
                >
                  {SearchByMenuItems.map((item) => (
                    <MenuItem key={item.key} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            {searchBy === 'date' ? (
              <>
               <SearchInput
               handleSearch={handleSearch}
               type={'date'}
               value={fromDate}
               name='fromDate'
               handleChangeSearch={handleChangeSearch}
               dateValidateError={dateValidateError}
               label={t('Search.FromDate')}
               handleClearDate={() => handleClearDate('fromDate')}
             />
              <SearchInput
               handleSearch={handleSearch}
               type={'date'}
               value={toDate}
               name='toDate'
               setTodayToDate={setToDate}
               handleChangeSearch={handleChangeSearch}
               dateValidateError={dateValidateError}
               label={t('Search.ToDate')}
               handleClearDate={() => handleClearDate('toDate')}
             />
            </>

            ) : searchBy === 'state' ? (
              <Box sx={classes.searchByStateBox}>
                {searchByStateButtons.map((btn, i) => {
                  return <Button
                    key={i}
                    sx={classes.searchByStateButtons}
                    name={btn.name}
                    variant={
                      searchByState === btn.name ? 'contained' : 'outlined'
                    }
                    color={btn.color}
                    onClick={() => handleSelectEncounterState(btn)}
                  >
                    {btn.name}
                  </Button>
                })}
              </Box>
            ) : (
              <SearchInput
              handleSearch={handleSearch}
              type={'text'}
              value={searchQuery}
              handleChangeSearch={handleChangeSearch}
              dateValidateError={dateValidateError}
              searchBy={searchBy}
              searchQuery={searchQuery}
              handleClearSearch={handleClearSearch}
            />
          )}
              
              {searchBy !== 'state' && (
                    <Button disabled={disaabledSearchButton} variant="contained" onClick={handleSearch} sx={classes.search_button}>
                      {t("Search.Search")}
                    </Button>
                  )}
            </Box>

            {!loadingEcounters && doctorEncountersCards.length > 0 ?
              doctorEncountersCards :
              loadingEcounters ?
                <Box sx={classes.loadEncounters}>
                  <CircularProgress/>
                </Box>:
                <Box sx={classes.not_found_message}>
                  <SentimentDissatisfiedIcon />
                  <Typography>{t("PatientEncountersNotFoundTitle")}</Typography>
                </Box>
            }
              
              {!loadingEcounters && count > 1 && <Box sx={classes.pagination}>
                  <Stack spacing={2} sx={classes.stack}>
                    <Pagination page={page} disabled={count === 1} count={count} variant="outlined" color="primary" size="large" onChange={(e, v) => setPage(v)} />
                  </Stack>
              </Box>}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PharmacyProfilePage;
