// validate doctor expenditure response data
// file: DoctorExpenditureBarChart.js && DoctorExpenditurePieChart.js
// url: '/api/analysis/doctor_prices'
export const validateDoctorExpenditure = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return Array.isArray(data) && data.every((object) => (
        typeof object === 'object' &&
        typeof object.total_cost === 'string' &&
        typeof object.accepted_cost === 'string' &&
        typeof object.rejected_cost === 'string' &&
        typeof object.provider === 'string'
    ));
};

// validate frequency doctor expenditure response data
// file: frequencyCharts/DoctorExpenditureBarChart.js
// url: '/api/analysis/doctor_prices'
export const validateFrequencyDoctorExpenditure = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return Array.isArray(data) && data.every((item) =>
        typeof item['provider_name'] === 'string' &&
        typeof item['type'] === 'string' &&
        Array.isArray(item['cost']) &&
        item['cost'].every((element) => typeof element === 'string') &&
        Array.isArray(item['date_list']) &&
        item['date_list'].every((element) => typeof element === 'string')
    );
};

// validate most used drugs response data
// file: MostUsedDrugsPieChart.js
// url: '/api/analysis/most_used_drugs'
export const validateMostUsedDrugs = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return typeof data === 'object' &&
           Array.isArray(data['most_used_drugs']) &&
           data['most_used_drugs'].every((object) => (
               typeof object === 'object' &&
               typeof object.drug_name === 'string' &&
               typeof object.drug_count === 'number'
           ));
};

// validate most used diagnosis response data
// file: MostUsedIcdsPieChart.js
// url: '/api/analysis/most_used_icds'
export const validateMostUsedDiagnosis = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return typeof data === 'object' &&
           Array.isArray(data['most_used_icds']) &&
           data['most_used_icds'].every((object) => (
               typeof object === 'object' &&
               typeof object.icd_code === 'string' &&
               typeof object.icd_count === 'number'
           ));
};

// validate encounter state chart response data
// files: StatePieChart.js, app-context
// url: '/api/analysis/encounter_state'
export const validateStateChart = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return Array.isArray(data) && data.every((object) => (
        typeof object === 'object' &&
        typeof object.state === 'string' &&
        typeof object.state_count === 'number'
    ));
};

// validate user profile response
// file: auth-context.js
// url: '/api/user/me'
export const validateUserProfile = (data) => {
    if (data === null || data === undefined || typeof data !== 'object' || typeof data['user'] !== 'object') {
        return false;
    }

    const {
        age,
        app_settings,
        birthdate,
        eid,
        email,
        employer,
        gender,
        id,
        name,
        nationality,
        phone_number,
        physical_address,
        roles
    } = data['user'];

    return (
        typeof data['id'] === 'number' &&
        typeof age === 'number' &&
        typeof app_settings === 'object' &&
        typeof birthdate === 'string' &&
        typeof eid === 'string' &&
        typeof email === 'string' &&
        typeof employer === 'string' &&
        typeof gender === 'string' &&
        typeof id === 'number' &&
        typeof name === 'string' &&
        typeof nationality === 'string' &&
        typeof phone_number === 'string' &&
        typeof physical_address === 'string' &&
        Array.isArray(roles)
    );
};

// validate new user response
// files: NewUserForm EncounterCard EncounterCardInsurance NewEncounterPage PatientProfilePage
// url: '/api/user/patientprofile/create'
export const validateUserResponse = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    const { id, primary_insurance_name, secondary_insurance_name, user } = data;

    return (
        typeof id === 'number' &&
        typeof primary_insurance_name === 'string' &&
        typeof secondary_insurance_name === 'string' &&
        typeof user === 'object'
    );
};

// validate patient diagnosis
// files: PatientProfilePage
// url: '/api/patient/encounter/icd/patientid/'
export const validatePatientDiagnosis = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return Array.isArray(data) && data.every((object) => {
        const { id, icd, shortdescription, date } = object;
        return (
            typeof id === 'number' && 
            typeof icd === 'string' &&
            typeof shortdescription === 'string' &&
            typeof date === 'string'
        );
    });
};

// validate icd options
// file: DiagnoseSelect
// url: 'api/medical/codes/icds/search'
export const validateIcdCode = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return Array.isArray(data) && data.every((object) => {
        const { icdcode, shortdescription } = object;
        return (
            typeof icdcode === 'string' &&
            typeof shortdescription === 'string'
        );
    });
}

// validate cpt options
// file: ProcedureSelect
// url: 'api/medical/codes/cpts/search'
export const validateCptCode = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return Array.isArray(data) && data.every((object) => {
        const { cptcode, shortdescription } = object;
        return (
            typeof cptcode === 'string' &&
            typeof shortdescription === 'string'
        );
    });
}

// validate drug options
// file: DrugName
// url: 'api/medical/codes/drugs/search'
export const validateDrugsCode = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return Array.isArray(data) &&
    data.every((object) => (
        typeof object.product_id === 'number' &&
        typeof object.product_brand_name === 'string'
    ));
};

// validate drugs for patient profile
// file:PatientProfilePage
// url: '/api/patient/encounter/drug/patientid/'
export const validatePatientProfileDrugsCode = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return Array.isArray(data) &&
    data.every((object) => (
        typeof object.drug === 'number' &&
        typeof object.packagename === 'string'
    ));
};

// validate user encounters
// files: NewEncounterForm, PatientProfilePage
// url: '/api/patient/encounter/patientid/'
export const validateUserEncounters = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return typeof data.count === 'number' &&
    Array.isArray(data.results);
};

// validate new encounter
// files: NewEncounterForm, EncounterCard, eEncounterCardInsurance
// url: '/api/patient/encounter/create/'
export const validateAnEncounter = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    const { id, provider, patient } = data;
    return typeof id === 'number' &&
     typeof provider === 'number' &&
     typeof patient === 'number';
};

// validate encounters list
// files: DoctorProfilePage, FacilityProfilePage, InsuranceProfilePage, PharmacyProfilePage
// url: '/api/patient/encounters/'
export const validateEncountersList = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    const { count, results } = data;
    return typeof count === 'number' &&
    Array.isArray(results);
};

// validate insurance and facilities list
// files: Settings, NewUserForm
export const validateInsuranceAndFacilitiesList = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return Array.isArray(data) && data.every((object) => (
        typeof object.id === 'number' && 
        typeof object.register_id === 'string'
    ));
};

// validate alternative drugs
// file: DrugName
export const validateAlternativeDrugs = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    const { count, results } = data;
    return typeof count === 'number' &&
    Array.isArray(results) && results.every((object) => (
        typeof object.product_id === 'number' &&
        typeof object.product_brand_name === 'string'
    ));
};

export const validateUpdatedUserPassword = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    const { id, email, name } = data;
    return typeof id === 'number' &&
    typeof email === 'string' &&
    typeof name === 'string';
};

export const validateAuthFormToken = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return typeof data === 'object' &&
    typeof data.token === 'string'
}

export const validateRelatedDrugs = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return Array.isArray(data) && data.every((object) => (
        typeof object.product_id === 'number' &&
        typeof object.product_brand_name === 'string'
    ))
}

export const validateFavourite = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    const {id, provider, icds, drugs, cpts} = data

    return typeof id === 'number' &&
    typeof provider === 'number' &&
    Array.isArray(icds) &&
    Array.isArray(drugs) &&
    Array.isArray(cpts)
}

export const validatePatientProcedures = (data) => {
    if (data === null || data === undefined) {
        return false;
    }

    return Array.isArray(data) && data.every((object) => {
        const { id, cpt, shortdescription, date } = object;
        return (
            typeof id === 'number' &&
            typeof cpt === 'string' &&
            typeof shortdescription === 'string' &&
            typeof date === 'string'
        );
    });
};
