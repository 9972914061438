import { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import classes from './Layout.module'
import SideBar from "./SideBar";
import MainNavigation from "./MainNavigation";
import { Outlet } from "react-router-dom";
import AppContext from '../../contexts/app-context';
import SimpleSnackbar from "../ui/SnackBar";

const Layout = () => {
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const appCtx = useContext(AppContext);
  const userRole = appCtx.userRole;
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenSidePanel(open);
  };
  
  const sideBarUserList = () => {
    return (
      <Box sx={classes.sidebar_container}>
        <SideBar toggleDrawer={toggleDrawer} />
      </Box>
    );
  };

  return (
    <Box>
      <Box sx={classes.container}>
        {userRole !== 'patient' ? sideBarUserList(): null}
        <Box sx={classes.navbar_container}>
          <MainNavigation toggleDrawer={toggleDrawer} />
          <Box sx={classes.outlet_container}>
            <Outlet />
          </Box>
        </Box>
      </Box>

      <Drawer
        anchor="left"
        open={openSidePanel}
        onClose={toggleDrawer(false)}
        sx={classes.drawer}
      >
        <SideBar toggleDrawer={toggleDrawer} />
      </Drawer>
      <SimpleSnackbar/>
    </Box>
  );
};

export default Layout;
