import React, { useEffect, useState } from 'react';
import useAxios from '../hooks/useAxios';
import { urls, toastify_status } from '../components/config/variables';
import { validateUserProfile } from '../Utils/validateHttpResponse'
import Toastify from '../components/ui/Toastify';

const AuthContext = React.createContext({
    token: '',
    isLoggedIn: false,
    userProfile: null,
    login: (token) => { },
    logout: () => { },
});


export const AuthContextProvider = (props) => {
    let initialToken =  localStorage.getItem('sehteq-token');
    let initialUser = localStorage.getItem('sehteq-user');
    const [token, setToken] = useState(initialToken);
    const [userProfile, setUserProfile] = useState(initialUser);
    const [error, setError] = useState(null);
    const { operation } = useAxios();
    const userIsLoggedIn = !!userProfile;

    const getLoggedInUserInfo = (token) => {
        operation({
          method: "GET",
          url: urls.LOGGED_USER,
          headers: { Authorization: `token ${token}` },
          data: {},
        }, handleSetUserProfile, setError);
    }

    const handleSetUserProfile = (userProfileResponse) => {
        if(validateUserProfile(userProfileResponse)) {
            setUserProfile(userProfileResponse)
        } else {
            console.log('invalid user profile response')
            Toastify({message: 'invalid user profile response', state: toastify_status.ERROR})
        }
    }

    useEffect(() => {
        if (!!token) {
            getLoggedInUserInfo (token);
            localStorage.setItem('sehteq-user', userProfile);
        }
        // eslint-disable-next-line
    },[token, JSON.stringify(userProfile)]);

    useEffect(() => {
        if (error){
            Toastify({message: 'login error please contact administrator', state: toastify_status.ERROR})
            console.log(error)
            logoutHandler();
            // if (error.response.status === 401) {
            //     console.log (error.response.status);
            //     logoutHandler();
            // }
        }
        // eslint-disable-next-line
    },[JSON.stringify(error)]);

    const logoutHandler =() => {
        setToken(null);
        setUserProfile(null)
        setError(null)
        localStorage.removeItem('sehteq-token');
        localStorage.removeItem('sehteq-user');
        localStorage.removeItem('sehteq-selecteduser'); // app-context
        localStorage.removeItem('sehteq-userslist');    // app-context
    }

    const loginHandler = (token) => {
        setToken(token);
        localStorage.setItem('sehteq-token', token);
    };

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        userProfile: userProfile,
        error: error,
        login: loginHandler,
        logout: logoutHandler,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
